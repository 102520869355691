export default [
  {
    path: '/profit-estimation-list',
    name: 'profit-estimation-list',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/FinanceReport/ProfitEstimationList'),
    meta: { permission: 'view-profit-estimation' }
  },
  {
    path: '/profit-estimation/:id',
    name: 'profit-estimation',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/FinanceReport/LotProfitEstimation'),
    meta: { permission: 'view-profit-estimation' }
  },
  {
    path: '/lot/create-lot-manifest',
    name: 'create-lot-manifest',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/components/CreateLotManifest.vue')
  },
  {
    path: '/lot/:id',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/LotParent'),
    reverse: 'lots-single',
    children: [
      {
        path: '',
        name: 'lot',
        component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/Lot'),
        reverse: 'lots-single'
      }
    ]
  },
  {
    path: '/lot/:id/reallocate-purchases',
    name: 'reallocate-purchases',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/LotReallocatePurchases')
  },
  {
    path: '/brokered-settlement/:id',
    name: 'brokered-settlement',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/components/Brokered/EditBrokeredSettlement'),
    reverse: 'brokered-settlement'
  },
  {
    path: '/brokered-settlement/:id/print',
    name: 'print-brokered-settlement',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/components/Brokered/BrokeredSettlementPrint')
  },
  {
    path: '/transit/lot-manifest/:id',
    name: 'lot-manifest',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/components/LotManifest.vue')
  },
  {
    path: '/lot/:id/finance',
    name: 'lot-finance',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/FinanceReport/LotFinance')
  },
  {
    path: '/shipment-summary/final-report/:id',
    name: 'lot-final-report',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/FinanceReport/FinalReport')
  },
  {
    path: '/lot-list',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/LotListParent'),
    children: [
      {
        path: '',
        name: 'lot-list',
        component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/LotList'),
        meta: { permission: 'view-lots' }
      },
      {
        path: 'shipped',
        name: 'lot-shipped-list',
        component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/LotShippedList'),
        meta: { permission: 'view-lots' }
      },
      {
        path: '/create-lot',
        name: 'create-lot',
        component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/CreateLot'),
        meta: { permission: 'create-lots' }
      }
    ]
  },
  {
    path: '/lot/:id/shipping-labels',
    name: 'shipping-labels',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Lots/components/ShippingLabels')
  },
  {
    path: '/shipment-summary/:id',
    name: 'shipment-summary',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/ShipmentSummaries/components/Read/ShipmentSummary'),
    reverse: 'shipment_summaries-single'
  },
  {
    path: '/shipment-summary-list',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/ShipmentSummaries/ShipmentSummaryParent'),
    children: [
      {
        path: '',
        name: 'shipment-summary-list',
        component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/ShipmentSummaries/ShipmentSummaryList'),
        meta: { permission: 'view-lots' }
      },
      {
        path: '/create-shipment-summary',
        name: 'create-shipment-summary',
        component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/ShipmentSummaries/components/Write/CreateShipmentSummary'),
        meta: { permission: 'create-lots' }
      }
    ]
  },
  {
    path: '/final-report-list',
    name: 'final-report-list',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/FinanceReport/FinalReportList'),
    meta: { permission: 'view-partial-report' }
  },
  {
    path: '/reports/smelter-shipment-history-report/',
    name: 'smelter-shipment-history-report',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Inventory/SmelterShipmentHistoryReport')
  },
  {
    path: '/reports/assay-comparison-by-lot/',
    name: 'assay-comparison-by-lot',
    component: () => import(/* webpackChunkName: "lot-routes" */ '@/views/Reports/AssayComparisonByLot.vue')
  }
]
