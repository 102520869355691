import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Vue from 'vue'

export default {
  getters: {
    roles: () => {
      const groups = Vue.prototype.$auth.user?.groups
      return !groups ? [] : groups
    },
    currentUser: () => {
      return Vue.prototype.$auth.user
    },
    /**
     * Passing back a function to see if a user has a role
     * @return {(function(*): (boolean|*))|*}
     */
    hasRole: () => (roleName) => {
      const groups = Vue.prototype.$auth.user?.groups
      if (!groups) return false
      return !!groups.find(r => r === roleName)
    },
    /**
     * Passing back function to see if user assigned to a trip
     * @return {(function(*): (boolean|*))|*}
     */
    isTripMember: () => (trip) => {
      const uid = Vue.prototype.$auth.user?.id
      if (!uid) return false
      if (!trip.users_details) return false
      return !!trip.users_details.find(ud => ud.id === uid)
    }
  },
  mutations: {
    setUser (state, accessToken) {
      state.currentUser = jwtDecode(accessToken)
    },
    setDarkTheme (state, bool) {
      state.darkTheme = bool
    }
  },
  actions: {
    /**
     * Update the user profile and refresh
     * @param commit
     * @param payload
     * @returns {Promise<void>}
     */
    async updateUser ({ commit }, payload) {
      if ('dark_mode' in payload) {
        commit('setDarkTheme', payload.dark_mode)
      }
      const response = await axios.patch('/api/refresh/', payload)
      commit('setUser', response.data.access_token)
    }
  }
}
