import axios from 'axios'

// Uses REGEX to strip the id out of a url
function getIdFromUrl (url) {
  const match = url.match(/(\d*)\/?$/gm)
  return match[0].replace('/', '')
}

export default {
  namespaced: true,
  state: {
    checkIn: null,
    latestMarketRate: null,
    latestPricing: [],
    searchResults: null,
    searchCatalog: null,
    configStaging: {},
    minPercent: 30
  },
  getters: {
    getShowFullValue: state => state.checkIn && state.checkIn.show_full_value,
    getCheckIn: state => state.checkIn,
    getMarketRate: state => state.checkIn && state.checkIn.market_rate,
    getConverters: state => {
      if (!state.checkIn || !state.checkIn.converter_lines) return null
      return state.checkIn.converter_lines.sort((a, b) => a.id - b.id)
    },
    getLines: state => {
      if (!state.checkIn || !state.checkIn.check_in_lines) return null
      return state.checkIn.check_in_lines.sort((a, b) => a.id - b.id)
    },
    getLatestMarketRate: state => state.latestMarketRate,
    getSearchResults: state => state.searchResults,
    getSearchCatalog: state => state.searchCatalog,
    getLatestPricing: state => state.latestPricing,
    getAllExceptFoilCount: state => {
      if (!state.checkIn) return 0
      return state.checkIn.external_total_counts.CV_TOTAL - (state.checkIn.external_total_counts.IF + state.checkIn.external_total_counts.DF)
    },
    getAllExceptFoilValue: state => {
      if (!state.checkIn) return 0
      return state.checkIn.external_total_values.CV_TOTAL - (state.checkIn.external_total_values.IF + state.checkIn.external_total_values.DF)
    },
    getFoilCount: state => {
      if (!state.checkIn) return 0
      return (state.checkIn.external_total_counts.IF + state.checkIn.external_total_counts.DF)
    },
    getFoilValue: state => {
      if (!state.checkIn) return 0
      return (state.checkIn.external_total_values.IF + state.checkIn.external_total_values.DF)
    },
    getGeneralPrice: state => state.checkIn && state.checkIn.total_prices.GN,
    getFoilPrice: state => state.checkIn && (state.checkIn.total_prices.IF + state.checkIn.total_prices.DF),
    getAllCount: state => state.checkIn && state.checkIn.external_total_counts.CV_TOTAL,
    getAllValue: state => state.checkIn && state.checkIn.external_total_values.CV_TOTAL,
    getDollarTotal: state => state.checkIn && state.checkIn.total_prices.total,
    getMinPercent: state => state.minPercent
  },
  mutations: {
    setCheckInDetails (state, data) {
      state.checkIn = null
      state.checkIn = data
    },
    setLatestMarketRate (state, data) {
      state.latestMarketRate = data
    },
    setSearchResults (state, data) {
      state.searchResults = data
    },
    setSearchCatalog (state, data) {
      state.searchCatalog = data
    },
    setLatestPricing (state, data) {
      state.latestPricing = data
    },
    updateLatestPrice (state, { converterUrl, price }) {
      state.latestPricing = [
        ...state.latestPricing.map(p => {
          if (converterUrl !== p.url) return p
          return Object.assign(p, { price })
        })
      ]
    },
    setMinPercent (state, minPercent) {
      state.minPercent = minPercent
    },
    setLatestPricingZero (state) {
      state.latestPricing = state.latestPricing.map(p => {
        if (p.price) p.price = 0
        return p
      })
    }
  },
  actions: {
    async fetchPricingMinPercent ({ commit }, checkinID) {
      try {
        let minPercent = await axios.get(`/api/check_ins/${checkinID}/pricing_options/`)
        commit('setMinPercent', minPercent.data.min_percent)
      } catch (err) {
        commit('setSnackbarError', 'An error occurred fetching the pricing min', { root: true })
      }
    },
    async createCheckIn ({ dispatch }) {
      // Test function for creating a new check in
      return axios.post('/api/check_ins/', {
        purchase: '/api/purchases/1/'
      }).then(response => {
        dispatch('fetchCheckInDetailed', { checkinID: response.data.id })
      })
    },
    /* fetches a checkin using the detailed view this includes a extra data including the market rate,
    * lines, converters
    * */
    async fetchCheckInDetailed ({ commit }, { checkinID }) {
      const response = await axios.get(`/api/check_ins/${checkinID}/detailed/`)
      commit('setCheckInDetails', response.data)
    },
    /**
     * Gets the latest market rate from the market_rate/latest end point
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchLatestMarketRate ({ commit }) {
      const r = await axios.get('/api/market_rates/latest/')
      commit('setLatestMarketRate', r.data)
      return r
    },
    async fetchLatestPrices ({ commit, getters }, params) {
      const ids = getters.getConverters.map(cv => getIdFromUrl(cv.converter.url))
      params.ids = ids.join(',')
      const config = { params }
      const results = await axios.get('/api/converters/price_many/', config)
      if (results.data) {
        commit('setLatestPricing', results.data)
      }
      return results
    },
    /**
     * Creates a new catalog line entry for the active check in
     * @param state
     * @param dispatch
     * @param catalogUrl
     * @param price
     * @returns {Promise<void>}
     */
    async addCatalogLine ({ state, dispatch }, { catalogUrl, price }) {
      await axios.post('/api/check_in_lines/', {
        check_in: state.checkIn.url,
        catalog: catalogUrl,
        price: price || 0
      })
      dispatch('fetchCheckInDetailed', { checkinID: state.checkIn.id }) // refresh
    },
    /**
     * Updates a catalog line for the catalog line url that is passed in
     * @param dispatch
     * @param state
     * @param catalogLineUrl
     * @param payload
     * @returns {Promise<void>}
     */
    async patchCatalogLine ({ dispatch, state }, { catalogLineUrl, payload }) {
      await axios.patch(catalogLineUrl, payload)
      dispatch('fetchCheckInDetailed', { checkinID: state.checkIn.id }) // refresh
    },
    /**
     * Triggers a Check In Refresh, for observing of the new lines entered
     * @param dispatch
     * @param converter
     * @returns {Promise<AxiosResponse<T>>}
     */
    async addConverterLine ({ state, dispatch }, { converterUrl, price }) {
      const r = await axios.post('/api/check_in_converters/', {
        check_in: state.checkIn.url,
        converter: converterUrl,
        price: price || 0
      })
      dispatch('fetchCheckInDetailed', { checkinID: state.checkIn.id }) // refresh
      return r
    },
    async patchConverterLine ({ dispatch, state }, { converterLineUrl, payload }) {
      await axios.patch(converterLineUrl, payload)
      dispatch('fetchCheckInDetailed', { checkinID: state.checkIn.id }) // refresh
    },
    async catalogSearch ({ commit }, { search, page }) {
      const params = { doc_type: 'catalog', search, page }
      const r = await axios.get('/api/app_search/', { params })
      commit('setSearchResults', r.data)
    },
    async fetchCatalog ({ commit }, { catalogID }) {
      const r = await axios.get(`/api/catalogs/${catalogID}/`)
      const data = r.data
      if (data.converter) {
        const converter = await Promise.all([
          axios.get(data.converter.url),
          axios.get(`${data.converter.url}price/`)
        ])
        data.converter = converter[0].data
        data.price = converter[1].data.price
      }
      commit('setSearchCatalog', data)
    },
    /**
     * Saves all of the latest pricing that has been stored
     * @param commit
     * @returns {Promise<void>}
     */
    async commitLatestPricing ({ getters, dispatch, state }) {
      // Sort the latest pricing into an object
      const pricing = getters.getLatestPricing.reduce((carry, price) => {
        carry[price.url] = price.price
        return carry
      }, {})
      // create axios requests
      const priceUpdates = await getters.getConverters.reduce((carry, line) => {
        const cvUrl = line.converter.url
        if (cvUrl in pricing) carry[getIdFromUrl(line.url)] = { price: pricing[cvUrl] }
        return carry
      }, {})
      // submitting price updates
      return axios.patch('/api/check_in_converters/bulk_update/', priceUpdates)
    },
    /**
     * Saves the current check ins market rate
     * @param dispatch
     * @param state
     * @param spread
     * @param showFullValue
     * @param showOverallAverage
     * @param marketRateUrl
     * @returns {Promise<void>}
     */
    async patchCheckIn ({ dispatch, state }, { marketRateUrl, spread, showFullValue, showOverallAverage }) {
      const payload = {}
      if (marketRateUrl !== null) payload['market_rate'] = marketRateUrl
      if (spread !== null) payload['pricing_spread'] = spread
      if (showFullValue !== null) payload['show_full_value'] = showFullValue
      if (showOverallAverage !== null) payload['show_overall_average'] = showOverallAverage
      await axios.patch(state.checkIn.url, payload)
      return dispatch('fetchCheckInDetailed', { checkinID: state.checkIn.id }) // refresh
    }
  }
}
