import axios from 'axios'

export default {
  namespaced: true,
  state: {
    purchases: [],
    purchase: [],
    purchaseStatuses: [
      { value: 'OP', text: 'Created' },
      { value: 'RE', text: 'Received' },
      { value: 'IN', text: 'Intook' },
      { value: 'SO', text: 'Sorted' },
      { value: 'CU', text: 'Cut' }
    ],
    availableLots: [],
    assignedLots: []
  },
  getters: {
    pieceCountLotOptions: state => {
      return [...state.assignedLots, ...state.availableLots]
    },
    purchases: state => state.purchases,
    purchase: state => state.purchase,
    purchaseStatuses: state => state.purchaseStatuses,
    getAvailableLots: state => state.availableLots
  },
  mutations: {
    setPurchases (state, purchases) {
      state.purchases = purchases
    },
    appendPurchases (state, purchases) {
      state.purchases = [...state.purchases, ...purchases]
    },
    setPurchase (state, purchase) {
      state.purchase = purchase
    },
    setAvailableLots (state, data) {
      state.availableLots = data
    },
    setAssignedLots (state, data) {
      state.assignedLots = data
    }
  },
  actions: {
    /**
     * Gets a page of purchases
     * @param {commit} commit sets purchase objects to store
     * @param {Object || Null} params - optional filters
     * @return {Promise<Integer>} - total number of entities
     */
    async fetchPurchases ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/purchases/', { params })
        if (params && params.page && params.page > 1) commit('appendPurchases', response.data.results)
        else commit('setPurchases', response.data.results)
        return response.data.count
      } catch {
        // Errors caught by axios Error Interceptor
      }
    },
    /**
     * Get a page of purchases using the detailed serializer
     * @param {commit} commit sets purchase objects to store
     * @param {Object || Null} params - optional filters
     */
    async fetchPurchasesDetailed ({ commit }, params = null) {
      try {
        const response = await axios.get('/api/purchases/detailed_list/', { params })
        let purchases
        if (params && params.page_size === 0) purchases = response.data
        else purchases = response.data.results
        commit('setPurchases', purchases)
      } catch {
        // Errors caught by axios Error Interceptor
      }
    },
    /**
     * Get all lots given optional query params
     * @param {Object} commit - access to store setters
     * @param {Object} params - optional filters
     */
    async fetchAllPurchases ({ commit }, params = null) {
      try {
        !params ? params = { page_size: 0 } : params.page_size = 0
        const response = await axios.get('/api/purchases/', { params })
        commit('setPurchases', response.data)
      } catch {
        // Errors caught by axios Error Interceptor
      }
    },
    /**
     * For individual purchase page, retrieves purchase information based off id (including check ins)
     * @param {object} commit Sets indicated purchase information
     * @param {Int} id Id of selected purchase
     */
    async fetchPurchase ({ commit }, params) {
      try {
        let response
        if (params.id) response = await axios.get(`/api/purchases/${params.id}/`)
        else if (params.url) response = await axios.get(params.url)
        const purchase = response.data
        const checkIns = purchase.check_ins.map(({ id }) => axios.get(`/api/check_ins/${id}/detailed/`))
        const checkInResults = await Promise.all(checkIns)
        purchase.check_ins = checkInResults.map(result => result.data)
        commit('setPurchase', purchase)
        return purchase
      } catch {
        // Errors caught by axios Error Interceptor
      }
    },
    /**
     * Create a purchase
     * @param {commit} commit - access to store setters
     * @param {Object} body - params of the new purchase
     */
    async createPurchase ({ commit }, body) {
      try {
        const response = await axios.post('/api/purchases/', body)
        commit('setPurchase', response.data)
        return response.data
      } catch {
        // Errors caught by axios Error Interceptor
      }
    },
    /**
     * Update a purchase
     * @param {commit} commit - access to store setters
     * @param {Object} body - body of the purchase to be updated and its id
     */
    async updatePurchase ({ commit }, body = null) {
      try {
        const params = body.params
        const response = await axios.patch(`/api/purchases/${body.id}/`, params)
        const purchase = response.data
        const checkIns = purchase.check_ins.map(({ id }) => axios.get(`/api/check_ins/${id}/detailed/`))
        const checkInResults = await Promise.all(checkIns)
        purchase.check_ins = checkInResults.map(result => result.data)
        commit('setPurchase', purchase)
      } catch {
        // Errors caught by axios Error Interceptor
      }
    },
    /**
     * Create a hedge and assign it to a given purchase
     * @param {commit} commit - access to store setters
     * @param {Object} params - params of the hedge to be created and id of the purchase
     */
    async createPurchaseHedge ({ commit }, params) {
      try {
        const response = await axios.post('/api/hedges/', params.body)
        const addToPurchase = await axios.patch(`/api/purchases/${params.id}/`, { hedge_id: response.data.id })
        commit('setPurchase', addToPurchase.data)
      } catch {
        // Errors caught by axios Error Interceptor
      }
    },
    /**
     * Update the link between a purchase and a lot
     * @param {commit} commit - access to store setters
     * @param {Object} params - An array of purchase ids to be assigned to a given lot
     */
    async updatePurchaseLot ({ commit }, params) {
      try {
        const purchases = params.purchases
        const lotId = params.id
        if (!purchases || !lotId) throw Error('Must include an array of purchase ids and a lot id')
        const promises = purchases.map(purchaseId => {
          return axios.post('/api/purchase_lots/', { lot_id: lotId, purchase_id: purchaseId })
        })
        await Promise.all(promises)
      } catch {
        // Errors caught by axios Error Interceptor
      }
    },
    /**
     * Fetches incomplete lots for editing pieceCountInformation
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchAvailableLots ({ commit }) {
      const r = await axios.get('/api/lots/?status__ne=CO&page_size=0&fields=name,url,id')
      if (r.data) commit('setAvailableLots', r.data)
    },
    /**
     * Fetches already attached lots for editing pieceCountInformation
     * @param commit
     * @param {Array} assignedLotIds
     * @returns {Promise<void>}
     */
    async fetchAssignedLots ({ commit }, assignedLotIds) {
      const lotIds = Array.from(new Set(assignedLotIds.filter(x => x)))
      if (lotIds.length === 0) return
      const params = { id__in: lotIds.join(','), page_size: 0, fields: 'name,url,id' }
      const r = await axios.get('/api/lots/', { params })
      if (r.data) commit('setAssignedLots', r.data)
    },
    async updateSinglePurchaseLot ({ state, dispatch }, { url, lotId }) {
      await axios.patch(url + '?fields=lot_id', { lot_id: lotId })
      let params = { id: state.purchase.id }
      return dispatch('fetchPurchase', params)
    },
    async createSinglePurchaseLot ({ state, dispatch }, { lotId, converterType }) {
      await axios.post('/api/purchase_lots/', {
        lot_id: lotId,
        purchase_id: state.purchase.id,
        converter_type: converterType
      })
      let params = { id: state.purchase.id }
      return dispatch('fetchPurchase', params)
    },
    async removeSinglePurchaseLot ({ dispatch, state }, { url }) {
      await axios.delete(url)
      let params = { id: state.purchase.id }
      return dispatch('fetchPurchase', params)
    }
  }
}
