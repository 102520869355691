<template>
  <v-toolbar-items>
    <v-btn
      icon
      @click="documentSearchDialog = !documentSearchDialog"
    >
      <v-icon>
        mdi-text-box-search-outline
      </v-icon>
    </v-btn>
    <v-menu
      v-if="$store.getters.currentUser"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
        >
          <v-list-item-icon>
            <v-icon>
              mdi-brightness-4
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $vuetify.theme.dark ? 'Light Mode' : 'Dark Mode' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'user-profile' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $store.getters.currentUser.username }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <document-search
      v-model="documentSearchDialog"
    />
  </v-toolbar-items>
</template>

<script>
import DocumentSearch from '@/base/components/DocumentSearch'
export default {
  name: 'AuthenticatedToolbarItems',
  components: { DocumentSearch },
  computed: {
    documentSearchDialog: {
      get () {
        return this.$store.getters['getDocumentSearchDialog']
      },
      set (v) {
        this.$store.commit('setDocumentSearchDialog', v)
      }
    }
  },
  methods: {
    /**
     * Clear the search and set search results to []
     */
    clearSearch () {
      this.setSearchResults([])
      this.search = ''
      this.page = 1
      this.prevNumResults = 0
    },
    logout () {
      if (confirm('Are you sure you would like to sign out?')) {
        this.$auth.logout()
      }
    }
  },
  watch: {
    '$vuetify.theme.dark' (setting) {
      this.$store.dispatch('updateUser', { dark_theme: setting })
    }
  },
  mounted () {
    this.showNavigation = this.$vuetify.breakpoint.mdAndUp
  }
}
</script>
