<template>
  <v-btn
    :rounded="rounded"
    v-bind="[$attrs, $props]"
    v-on="$listeners"
    :fab="!$vuetify.breakpoint.smAndUp && rounded"
  >
    <span
      v-if="$vuetify.breakpoint.smAndUp"
      class="mr-2"
    >
      <slot />
    </span>
    <v-icon
      v-if="iconText"
      small
    >
      {{ iconText }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'TextFab',
  props: {
    iconText: {
      type: String,
      required: false,
      default: null
    },
    rounded: {
      type: Boolean,
      default: true
    }
  }
}
</script>
