<template>
  <v-dialog
    content-class="my-custom-dialog"
    max-width="800"
    transition="slide-y-transition"
    :value="value"
    scrollable
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-toolbar
        flat
      >
        <v-toolbar-items>
          <v-text-field
            class="pt-2"
            v-model="search"
            :loading="loading"
            label="Document Search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
            autofocus
            clearable
          />
        </v-toolbar-items>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            @click="$emit('input', false)"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-list three-line>
          <div
            v-for="(doc, index) in documentData"
            :key="doc.id"
          >
            <v-divider
              v-if="index !== 0"
              inset
            />
            <v-list-item
              :to="doc.link"
              @click="closeDialog(doc)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ doc.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ doc.document }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <v-fade-transition>
          <div v-show="!documentData.length">
            <p>
              Document search will show the 50 most recently updated entries regarding a search.<br>
              The files are index and searchable by configured searchable fields.
            </p>
            <p>
              You can further improve results by typing the type of document followed by the id. <code>hedge 123</code><br>
              Furthermore if you know the exact id enter <code>hedge-123</code>
            </p>
            <p>
              <em>
                Hint: you can middle mouse click to open lines in new tabs
                <br>
                Hint: The shortcut for opening the Document Search is <code>~</code> the key right under escape.
              </em>
            </p>
          </div>
        </v-fade-transition>
        <!--        <v-data-table-->
        <!--          :headers="tableHeaders"-->
        <!--          :items="documentData"-->
        <!--          style="cursor: pointer"-->
        <!--          @click:row="gotoDocument"-->
        <!--        />-->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DocumentSearch',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    documentData: [],
    timeout: null,
    loading: false,
    tableHeaders: [
      { text: 'Name', sortable: true, value: 'name' },
      { text: 'Info', sortable: true, value: 'document' }
    ],
    docHandlers: {
      BrokeredSettlement: { routeName: 'brokered-settlement' },
      CheckIn: { routeName: 'check-in' },
      Hedge: { routeName: 'hedge' },
      Intake: { routeName: 'intake' },
      Inventory: { routeName: 'inventory' },
      Lot: { routeName: 'lot' },
      PpmCalculation: { routeName: 'ppm-calculation' },
      Purchase: { routeName: 'purchase' },
      ShipmentSummary: { routeName: 'shipment-summary' },
      Yard: { routeName: 'yard' },
      Smelter: { routeName: 'smelter' },
      Expense: { routeName: 'expense' },
      FinalReport: { routeName: 'lot-final-report' },
      InventoryManifest: { routeName: 'manifest-view' },
      ProfitEstimation: { routeName: 'profit-estimation' },
      SpendingPlan: { routeName: 'spending-plan-report' },
      Trip: { routeName: 'trip' },
      TripBlueprint: { routeName: 'blueprint' },
      Broker: { routeName: 'broker-view' },
      Carrier: { routeName: 'carrier-view' },
      Transit: { routeName: 'transit-view' }
    }
  }),
  computed: {
  },
  watch: {
    value (open) {
      if (open) this.search = ''
    },
    search (v) {
      this.fetchDocuments(v)
    }
  },
  methods: {
    /**
     * Apply navigation for a table click
     * @param doc
     */
    closeDialog () {
      this.$emit('input', false)
    },
    /**
     * Formats doc data before setting it to a local variable
     * @param docs
     */
    setDocuments (docs) {
      const routeHandlers = {}
      for (const key in this.docHandlers) {
        routeHandlers[key.toLowerCase()] = id => ({ name: this.docHandlers[key].routeName, params: { id } })
      }
      this.documentData = docs.map(doc => {
        const [modelName, pk] = doc.id.split('-')
        const document = doc.document.split('||').join(', ')
        const link = routeHandlers[modelName](pk)
        return Object.assign({ modelName, pk, link }, doc, { document })
      })
    },
    /**
     * Performs the lookup queries for doc search
     * @param val
     */
    fetchDocuments (val) {
      if (this.timeout) clearTimeout(this.timeout)
      if (!val) {
        this.setDocuments([])
        return
      }
      this.timeout = setTimeout(async () => {
        this.loading = true
        const response = await axios.get('/api/app_search/?search=' + val)
        this.loading = false
        this.setDocuments(response.data.results)
      }, 450)
    }
  }
}
</script>

<style scoped>
.my-custom-dialog {
  align-self: flex-start;
}
</style>
