<template>
  <v-app-bar
    ref="navbar"
    clipped-left
    app
    dense
    flat
    height="36"
  >
    <slot name="left" />
    <router-link
      to="/"
      style="text-decoration: none; color: inherit;"
    >
      <v-img
        v-if="this.$vuetify.breakpoint.smAndUp"
        src="@/assets/trackkat-logo.svg"
        alt="Track Kat"
        max-height="28"
        max-width="28"
        contain
      />
    </router-link>
    <router-link
      to="/"
      style="text-decoration: none; color: inherit;"
    >
      <v-toolbar-title
        class="pl-2"
        v-if="this.$vuetify.breakpoint.mdAndUp"
      >
        TRACKKAT
      </v-toolbar-title>
    </router-link>
    <v-spacer />
    <info-tooltip>
      <template v-slot:wrap-me>
        <v-progress-circular
          v-if="$vuetify.breakpoint.smAndUp"
          v-show="$store.getters.isLoading"
          :indeterminate="true"
          class="mr-5"
          color="red lighten-1"
          size="20"
          width="3"
          absolute
          bottom
        />
      </template>
      Saving changes...
    </info-tooltip>
    <slot />
    <v-progress-linear
      v-if="$vuetify.breakpoint.xs"
      :active="$store.getters.isLoading"
      :indeterminate="true"
      color="red"
      absolute
      bottom
      height="2"
    />
  </v-app-bar>
</template>
<script>

export default {
  name: 'AppToolbar',
  data: () => ({
    displaySearchMenu: false
  }),
  methods: {
    /**
     * Toggle whether or not the show the app navigation drawer
     */
    toggleAppNavDisplay () {
      this.$store.commit('toggleDisplayingAppNav')
    }
  }
}
</script>

<style scoped>
.invert-img {
  -webkit-filter: invert(100%); /* Safari 6.0 - 9.0 */
  filter: invert(100%);
}
</style>
